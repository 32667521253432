import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tab', 'panel'];
  static classes = ['tabActive', 'panelHidden'];

  connect() {
    this.showTab(this.index);
  }

  change(event) {
    event.preventDefault();
    this.index = this.tabTargets.indexOf(event.currentTarget);
  }

  showTab(index) {
    this.tabTargets.forEach((tab, i) => {
      const panel = this.panelTargets[i];
      tab.classList.toggle(this.tabActiveClass || '-active', i === index);
      if (this.panelTargets.length === 1) return;
      panel.classList.toggle(this.panelHiddenClass || 'hidden', i !== index);
    });
  }

  get index() {
    return parseInt(this.data.get('index') || 0);
  }

  set index(value) {
    this.data.set('index', value);
    this.showTab(value);
  }
}
